var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-country')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"country",staticClass:"row",attrs:{"enctype":"multipart/form-data"}},[_c('b-form-group',{staticClass:"col-sm-6",attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Name "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.name.$error,
              },attrs:{"id":"input-1","placeholder":"Enter Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.submitted && !_vm.$v.form.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"id":"input-group-2"}},[_c('label',{attrs:{"for":"input-2"}},[_vm._v("Sortname "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.sortname.$error,
              },attrs:{"id":"input-2","placeholder":"Enter Sortname"},model:{value:(_vm.form.sortname),callback:function ($$v) {_vm.$set(_vm.form, "sortname", $$v)},expression:"form.sortname"}}),(_vm.submitted && !_vm.$v.form.sortname.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Sortname is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"id":"input-group-3"}},[_c('label',{attrs:{"for":"input-3"}},[_vm._v("Phone Code "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                'is-invalid': _vm.submitted && _vm.$v.form.phonecode.$error,
              },attrs:{"id":"input-3","placeholder":"Enter Phone Code"},model:{value:(_vm.form.phonecode),callback:function ($$v) {_vm.$set(_vm.form, "phonecode", $$v)},expression:"form.phonecode"}}),(_vm.submitted && _vm.$v.form.phonecode.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phonecode.required)?_c('span',[_vm._v("Phonecode is required.")]):_vm._e(),(!_vm.$v.form.phonecode.numeric)?_c('span',[_vm._v("This value should be a valid number...")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"col-sm-6",attrs:{"id":"input-group-3","label":"Flag Image [Upload Max File Size : 2MB]","label-for":"input-20"}},[_c('b-form-file',{ref:"image_name",attrs:{"id":"input-20","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'image_name')}}}),(_vm.$route.name == 'edit-country' && _vm.edit.image_name_url)?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.image_name_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.image_name_url)?[_c('img',{ref:"image_name_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.image_name_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-country')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }