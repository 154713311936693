<template>
  <Layout>
    <template v-if="this.$route.name == 'add-country'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="country" class="row">
              <b-form-group id="input-group-1" class="col-sm-6">
                <label for="input-1">Name <span style="color: red;">*</span></label>
                <b-form-input id="input-1" v-model="form.name" placeholder="Enter Name" :class="{
                  'is-invalid': submitted && $v.form.name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                  Name is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-2" class="col-sm-6">
                <label for="input-2">Sortname <span style="color: red;">*</span></label>
                <b-form-input id="input-2" v-model="form.sortname" placeholder="Enter Sortname" :class="{
                  'is-invalid': submitted && $v.form.sortname.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.sortname.required" class="invalid-feedback">
                  Sortname is required.
                </div>
              </b-form-group>
              <b-form-group id="input-group-3" class="col-sm-6">
                <label for="input-3">Phone Code <span style="color: red;">*</span></label>
                <b-form-input id="input-3" v-model="form.phonecode" placeholder="Enter Phone Code" :class="{
                  'is-invalid': submitted && $v.form.phonecode.$error,
                }"></b-form-input>
                <div v-if="submitted && $v.form.phonecode.$error" class="invalid-feedback">
                  <span v-if="!$v.form.phonecode.required">Phonecode is required.</span>
                  <span v-if="!$v.form.phonecode.numeric">This value should be a valid number...</span>
                </div>
              </b-form-group>
              <b-form-group id="input-group-3" label="Flag Image [Upload Max File Size : 2MB]"
              label-for="input-20" class="col-sm-6">
              <b-form-file id="input-20" accept="image/*" placeholder="Choose a file or drop it here..."
                @change="readFile($event, 'image_name')" ref="image_name"></b-form-file>
              <template v-if="$route.name == 'edit-country' && edit.image_name_url">
                <img :src="edit.image_name_url" width="128px" height="128px"
                  style="object-fit: contain; margin-top: 5px" />
              </template>
              <template v-if="image_name_url">
                <img :src="image_name_url" width="128px" height="128px" ref="image_name_url"
                  style="object-fit: contain; margin-top: 5px" />
              </template>
            </b-form-group>
              <b-button type="submit" variant="primary" class="mr-2" @click.prevent="submitData"><span
                  v-if="this.$route.name == 'add-country'">Save Data</span>
                <span v-else>Update Data</span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import countryMixin from "../../../mixins/ModuleJs/country";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, countryMixin],
  data() {
    return {
      submitted: false,
      title1: "Add Country",
      title2: 'Edit Country',
      items: [
        {
          text: "List",
          href: "/country",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      name: { required },
      sortname: { required },
      phonecode: { required, numeric },
    },
  },
};
</script>
